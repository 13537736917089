import './fixedHeader'

const hamburgerMenu = (body, hamburger, hamburgers) => {

  const menuLink = document.querySelector('.rs-nav__menu--button');

  if (hamburger) {

    hamburger.addEventListener('click', () => {

      if (body.style.overflow === "hidden") {
        body.style.overflow = "inherit";
      } else {
        body.style.overflow = 'hidden';
      }

      body.classList.toggle('rs-nav--active');

      hamburgers.forEach((e) => {
        e.getElementsByClassName('rs-hamburger-button__group')[0].classList.toggle('active');
      })
    });

    menuLink.addEventListener('click', () => {

      if (body.style.overflow === "hidden") {
        body.style.overflow = "inherit";
      } else {
        body.style.overflow = 'hidden';
      }

      body.classList.toggle('rs-nav--active');

      hamburgers.forEach((e) => {
        e.getElementsByClassName('rs-hamburger-button__group')[0].classList.toggle('active');
      })
    });
  }
}

const accessibility = () => {

  let nav = document.querySelectorAll('.rs-nav__menu')

  Array.prototype.slice.call(nav).forEach((menuElements) => {

    let allowMultiple = menuElements.hasAttribute('data-allow-multiple')
    let allowToggle = true

    menuElements.addEventListener('click', function (event) {

        let target = event.target;

        if (target.classList.contains('menu-item-has-children') && target.children[0].href == '#') {

            event.preventDefault();
        }

        if (target.classList.contains('menu-item-has-children') && target.nodeName !== 'A') {

            let isExpanded = target.children[0].getAttribute('aria-expanded') === 'true';
            let active = menuElements.querySelector('[aria-expanded="true"]');

            if (!allowMultiple && active && active !== target.children[0]) {

                active.setAttribute('aria-expanded', 'false');
                target.classList.remove('active');

                if( !allowToggle ) {

                    active.removeAttribute('aria-disabled');
                }
            }

            if (!isExpanded) {

                target.children[0].setAttribute('aria-expanded', 'true');
                target.classList.add('active');

                if( !allowToggle ) {

                    target.children[0].setAttribute('aria-disabled', 'true');
                }

            } else if (allowToggle && isExpanded) {

                target.children[0].setAttribute('aria-expanded', 'false');
                target.classList.remove('active');
            }
        }
    })
  })
}

const menuItems = (body) => {

  let menuItemHasChildren = document.querySelectorAll('.menu-item-has-children');

  menuItemHasChildren.forEach( (item) => {

    item.addEventListener('click', function(e) {

      if (body.classList.contains('rs-nav--active')) {

        e.target.parentElement.classList.toggle('current-menu-item')
        e.target.classList.toggle('current-menu-item')
      }
    })
  })
}

document.addEventListener('DOMContentLoaded', () => {

  var archiveHeader = document.querySelector('.rs-archive__header');

  if (archiveHeader){
    
    var colour = getComputedStyle(archiveHeader).backgroundColor.split("(")[1].split(")")[0].split(',');
    var luminance =  Math.round((0.2126 * colour[0] + 0.7152 * colour[1] + 0.0722 * colour[2]) / 255 * 100);
    var categoryTitle =  document.querySelector('.rs-archive__name');
    var subcategoryTitles = document.querySelectorAll('.rs-archive--category .rs-archive__nav li a');

    if(luminance >= 70) {
      categoryTitle.style.color = "black";
      for(var i = 0; i <= subcategoryTitles.length; i++){
        subcategoryTitles[i].style.color = "black";
      }
    }

  }
});

document.addEventListener('DOMContentLoaded', () => {

  const body = document.body;
  const mqList = window.matchMedia('(min-width: 1024px)');

  let hamburgers = document.querySelectorAll('.rs-hamburger-button');

  hamburgers.forEach((hamburger) => {
    hamburgerMenu(body, hamburger, hamburgers);
  })

  accessibility();
  menuItems(body);
});